<template>
  <div class="vd-padding-medium">
    <div class="vd-h4 vd-light-blue vd-margin-bottom-30">
      <strong>HubSpot quotes</strong>

      <a
        v-if="canDisplayHubspotDealBtn"
        class="vd-btn-small vd-btn-blue vd-margin-left-small"
        :href="project.hubspot_deal_url"
        target="_blank"
        >View HubSpot deal</a
      >
    </div>

    <default-loader
      v-if="isHubspotQuotesLoading"
      class="vd-margin-top-100"
    ></default-loader>

    <div v-else>
      <project-invoice-empty-state
        v-if="!hasHubSpotQuotes"
        :empty-state-message="emptyStateMessage"
      >
        <a
          v-if="project.hubspot_deal_url"
          slot="extra-info"
          class="vd-btn-small vd-btn-blue vd-margin-left-small vd-margin-top-small"
          :href="project.hubspot_deal_url"
          target="_blank"
          >View HubSpot deal</a
        >
      </project-invoice-empty-state>

      <template v-else>
        <project-invoices-hubspot-quotes-desktop
          v-if="!isMobileScreen"
        ></project-invoices-hubspot-quotes-desktop>

        <project-invoices-hubspot-quotes-mobile
          v-else
        ></project-invoices-hubspot-quotes-mobile>
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import ProjectInvoiceEmptyState from '@views/project/invoices/components/project-invoices-empty-state'
import ProjectInvoicesHubspotQuotesDesktop from '@views/project/invoices/project-invoices-hubspot-quotes/project-invoices-hubspot-quotes-desktop'
import ProjectInvoicesHubspotQuotesMobile from '@views/project/invoices/project-invoices-hubspot-quotes/project-invoices-hubspot-quotes-mobile'
import DefaultLoader from '@components/loaders/default-loader'
import { size } from 'lodash'

export default {
  components: {
    ProjectInvoiceEmptyState,
    ProjectInvoicesHubspotQuotesDesktop,
    ProjectInvoicesHubspotQuotesMobile,
    DefaultLoader,
  },

  computed: {
    ...mapGetters({
      project: 'project/projectDetails',
      hubspotQuotes: 'project/hubspotQuotes',
      isHubspotQuotesLoading: 'project/isHubspotQuotesLoading',
      isMobileScreen: 'common/isMobileScreen',
    }),

    hasHubSpotQuotes() {
      return !this.isHubspotQuotesLoading && size(this.hubspotQuotes) > 0
    },

    canDisplayHubspotDealBtn() {
      return this.project.hubspot_deal_url && this.hasHubSpotQuotes
    },

    emptyStateMessage() {
      return !this.hasHubSpotQuotes && !this.project.hubspot_deal_url
        ? 'No HubSpot quotes or deals are connected to this project.'
        : 'No HubSpot quotes are connected to this project.'
    },
  },

  mounted() {
    this.getHubspotQuotes(this.project.id)
  },

  methods: {
    ...mapActions({
      getHubspotQuotes: 'project/getHubspotQuotes',
    }),
  },
}
</script>
