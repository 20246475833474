<template>
  <table class="vms-invoices__table table table-sm">
    <thead>
      <tr>
        <th scope="col" class="vd-h4 vd-darker-grey vd-border-light-grey">
          Quote name
        </th>
        <th scope="col" class="vd-h4 vd-darker-grey vd-border-light-grey">
          Quote amount
        </th>
        <th scope="col" class="vd-h4 vd-darker-grey vd-border-light-grey">
          Created date
        </th>
        <th
          scope="col"
          class="vd-h4 vd-darker-grey vd-border-light-grey text-center"
        >
          Actions
        </th>
      </tr>
    </thead>
    <tbody>
      <project-hub-spot-quotes-row
        v-for="(hubspotQuote, index) in hubspotQuotes"
        :key="index"
        :hubspot-quote="hubspotQuote"
      ></project-hub-spot-quotes-row>
    </tbody>
  </table>
</template>

<script>
import { mapGetters } from 'vuex'
import ProjectHubSpotQuotesRow from '@views/project/invoices/project-invoices-hubspot-quotes/project-invoices-hubspot-quotes-row'

export default {
  components: {
    ProjectHubSpotQuotesRow,
  },

  computed: {
    ...mapGetters({
      hubspotQuotes: 'project/hubspotQuotes',
    }),
  },
}
</script>
