<template>
  <div class="vms-invoices__table--empty vd-text-align-center">
    <img src="/images/empty-states/empty-invoices.svg" />
    <div class="vd-padding-top-20 vd-black">
      {{ emptyStateMessage }}
    </div>

    <slot name="extra-info"></slot>
  </div>
</template>

<script>
export default {
  props: {
    emptyStateMessage: {
      type: String,
      required: true,
    },
  },
}
</script>
