<template>
  <tr>
    <td class="vd-border-light-grey">
      <a
        v-if="hasAttachments"
        :href="hubspotQuote.attachments[0].url"
        class="vd-a vms-invoices__view-invoice-link"
        target="_blank"
        rel="noopener"
      >
        {{ hubspotQuote.name }}
      </a>
      <span v-else>{{ hubspotQuote.name }}</span>
    </td>
    <td class="vd-border-light-grey">
      {{ hubspotQuote.amount | moneyFormat }}
    </td>
    <td class="vd-border-light-grey">
      <span v-if="hubspotQuote.quote_created_at">
        {{ hubspotQuote.quote_created_at | unixToFormat('DD MMM YYYY') }}
      </span>
    </td>

    <td class="vd-border-light-grey text-center">
      <a
        v-if="hasAttachments"
        :href="hubspotQuote.attachments[0].url"
        class="vd-a vms-invoices__view-invoice-link"
        target="_blank"
        rel="noopener"
      >
        <i
          class="vd-icon icon-download-storage vd-cursor-pointer vd-hover-blue"
        ></i>
      </a>
    </td>
  </tr>
</template>

<script>
import { size } from 'lodash'
import FiltersMixin from '@mixins/filters-mixin'

export default {
  mixins: [FiltersMixin],
  props: {
    hubspotQuote: {
      type: Object,
      required: true,
    },
  },

  computed: {
    hasAttachments() {
      return size(this.hubspotQuote.attachments) > 0
    },
  },
}
</script>
