<template>
  <div class="vms-invoices-list">
    <div v-for="(hubspotQuote, index) in hubspotQuotes" :key="index">
      <div
        class="vms-invoices-list__item vd-background-white vd-border-width-1 vd-border-light-grey vd-border-radius-6 vd-box-shadow vd-padding-medium vd-margin-bottom-medium vd-margin-top-medium"
      >
        <div class="vms-invoices-list__item">
          <div class="vd-darker-grey">Quote name</div>
          <div>
            <a
              v-if="hasAttachments(hubspotQuote)"
              :href="hubspotQuote.attachments[0].url"
              class="vd-a vms-invoices__view-invoice-link"
              target="_blank"
              rel="noopener"
            >
              {{ hubspotQuote.name }}
            </a>
            <span v-else>{{ hubspotQuote.name }}</span>
          </div>
        </div>

        <hr class="vd-border-grey" />

        <div class="row no-gutters">
          <div class="col-4">
            <div class="vd-margin-right-small">
              <div class="vd-darker-grey small">Quote amount</div>
              <div>{{ hubspotQuote.amount | moneyFormat }}</div>
            </div>
          </div>

          <div class="col-4">
            <div class="vd-margin-right-small">
              <div class="vd-darker-grey small">Created date</div>
              <div>
                <span v-if="hubspotQuote.quote_created_at">
                  {{
                    hubspotQuote.quote_created_at | unixToFormat('DD MMM YYYY')
                  }}
                </span>
              </div>
            </div>
          </div>

          <div class="col-4 vd-text-align-center">
            <div class="vd-margin-right-small">
              <div class="vd-darker-grey small">Actions</div>
              <div v-if="hasAttachments(hubspotQuote)">
                <a
                  :href="hubspotQuote.attachments[0].url"
                  class="vd-a vms-invoices__view-invoice-link"
                  target="_blank"
                  rel="noopener"
                >
                  <i
                    class="vd-icon icon-download-storage vd-cursor-pointer vd-hover-blue"
                  ></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { size } from 'lodash'
import FiltersMixin from '@mixins/filters-mixin'

export default {
  mixins: [FiltersMixin],

  computed: {
    ...mapGetters({
      hubspotQuotes: 'project/hubspotQuotes',
    }),
  },

  methods: {
    hasAttachments(hubspotQuote) {
      return size(hubspotQuote.attachments) > 0
    },
  },
}
</script>
